@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/derived-variables';

.sidebar-cart {
  z-index: 31;
  color: black;
  border-left: 10px solid #1c1912;
  padding: 1.5em;
  text-align: left;
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  // background: #b66d0c;
  background: white;
  box-sizing: border-box;

  .trashButton {
    background-color: unset;
    border: 0;
    color: black;
    &:hover {
      background-color: unset;
      color: black;
      border: 1px solid #3e3e3e;
    }
  }

  @media (max-width: $tablet) {
    width: 100vw;
  }

  transition: right 0.2s;

  &--open {
    right: 0;
  }

  &.undocked {
    position: unset;
    background: unset;
  }
}
