body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .button {
    background-color: #fff;
    border-color: #ccc;
  }
  .button:hover {
    background-color: #fafafa;
    border-color: #b5b5b5;
    filter: brightness(0.95);
  }
  .button.active-toggle-option {
    background-color: #fafafa;
    border-color: #b5b5b5;
    z-index: 1;
    filter: brightness(0.95);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
