@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/derived-variables';

.toggleButtonGroup {
  :global {
    div.columns.tips-buttons-field {
      margin-bottom: 0;
    }
    .toggle-button.column ~ .toggle-button.column {
      margin-left: 10px;
    }
    .columns {
      margin: 0;
      .column {
        padding: 0;
        margin: 0;
      }
    }
    .tip-button {
      height: 50px;
    }
  }
}
