/* Variables */
:root {
  --body-color: rgb(247, 250, 252);
  --button-color: rgb(30, 166, 114);
  --accent-color: #0a4772;
  --cash-color: #0a721b;
  --link-color: #ffffff;
  --font-color: rgb(105, 115, 134);
  --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --radius: 6px;
  --form-width: 380px;
}

.CardField-input-wrapper * {
  font-size: 14px !important;
}

/* Base */
* {
  box-sizing: border-box;
}
.cash-input {
  text-align: right;
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);
  border: none;
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #32325d;
  &:focus {
    outline-width: 0;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aab7c4;
  }
}
.button.cashButton {
  background: var(--cash-color);
  color: white;
}
// .button.creditButton {
//   background: var(--accent-color);
//   color: white;
// }
body {
  font-family: var(--body-font-family);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}
.sr-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: 200px;
  margin: 20px auto;
  margin-top: 0;
  padding: 25px;
  border-radius: 4px;
  background: var(--body-color);
  border: 1px solid #b5b5b5;
  position: relative;
  z-index: 2;
}

.sr-field-error {
  color: var(--font-color);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

/* Inputs */
.sr-input,
input[type='text'] {
  border: 1px solid var(--gray-border);
  border-radius: var(--radius);
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.sr-input:focus,
input[type='text']:focus,
button:focus,
.focused {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
  z-index: 9;
}
.sr-input::placeholder,
input[type='text']::placeholder {
  color: var(--gray-light);
}
.sr-result {
  height: 44px;
  -webkit-transition: height 1s ease;
  -moz-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
  color: var(--font-color);
  overflow: auto;
}
.sr-result code {
  overflow: scroll;
}
.sr-result.expand {
  height: 350px;
}

.sr-combo-inputs-row {
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

/* Buttons and links */
.sr-main button {
  margin-top: 10px;
}
button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.checkout-types .toggle-button button {
  border-radius: 0;
  border-bottom: 0;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: all 0.2s ease;
}

// a:hover {
//   filter: brightness(0.8);
// }

a:active {
  filter: brightness(0.5);
}

/* Code block */
code,
pre {
  font-family: 'SF Mono', 'IBM Plex Mono', 'Menlo', monospace;
  font-size: 12px;
}

/* Stripe Element placeholder */
.sr-card-element {
  padding-top: 12px;
}

.banner {
  max-width: 825px;
  margin: 0 auto;
  padding: 0 22px;
  font-size: 14px;
  background: white;
  color: #6a7c94;
  border-radius: 22px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  line-height: 1.15;
  position: absolute;
  bottom: 2vh;
  left: 0;
  right: 0;
  text-align: center;
}

.banner span {
  display: inline-block;
  width: 100%;
}

.banner a {
  color: var(--accent-color);
}

/* Responsiveness */
@media (max-width: 720px) {
  .sr-root {
    flex-direction: column;
    justify-content: flex-start;
    //padding: 48px 20px;
    min-width: 320px;
  }

  .sr-header__logo {
    background-position: center;
  }

  .sr-payment-summary {
    text-align: center;
  }

  .sr-content {
    display: none;
  }

  .sr-main {
    width: 100%;
    //height: 305px;
    background: rgb(247, 250, 252);
    border: 1px solid #b5b5b5;
    position: relative;
    z-index: 2;
  }
}

/* todo: spinner/processing state, errors, animations */

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: var(--accent-color);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: var(--accent-color);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Animated form */

.sr-root {
  animation: 0.4s form-in;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.hidden {
  display: none;
}

@keyframes field-in {
  0% {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes form-in {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
